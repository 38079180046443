<template>
    <marketing-layout :has-landing-footer="true">
        <div class="has-navbar container-fluid px-0">
            <section-block class="pt-3 pt-md-8 pb-0 pb-sm-1">
                <div
                    id="origination-form"
                    class="container"
                >
                    <div class="row">
                        <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-0 text-start">
                            <section-header variant="text-primary">
                                Low Variable APRs<sup><a href="#footnote_1">1</a></sup>
                            </section-header>
                            <h1 class="mb-3">
                                <img
                                    class="img-fluid"
                                    :src="require(`@/assets/images/experiments/${heraclesParameter.EXPERIMENT_NAME}/apr.svg`)"
                                    :alt="$t(`experiments.${heraclesParameter.EXPERIMENT_NAME}.aprAltText`)"
                                >
                            </h1>
                            <h3 class="text-responsive mb-1">
                                Home equity rates.<br>On a credit card.
                            </h3>
                            <h5 class="text-responsive fw-light mb-3 me-lg-3">
                                Get rates like a home equity line of credit but with the simplicity of a credit card. Plus 1.5% unlimited cashback.<sup><a href="#footnote_5">5</a></sup> Decision in
                                minutes. 100% online.
                            </h5>
                            <origination-invite-form
                                id-prefix="main-origination-form"
                                class="pr-lg-8"
                                :code-required="codeRequired"
                                ref="originationInviteFormHero"
                                :show-forgot-code-option="showForgotCodeOption"
                                :forgot-code-link="heraclesParameter.JOIN_PATH"
                                show-safe-credit-score
                                cta-text="Check your offer"
                            />
                        </div>
                        <card-hand-medium-large />
                    </div>
                </div>
                <div class="mobile-image" />
            </section-block>

            <card-hand-small />

            <div class="container centered mt-3">
                <hr>
            </div>

            <what-is-it-section />

            <divider-line />

            <section-block>
                <div class="container">
                    <div class="row row align-items-center">
                        <div class="col">
                            <section-header
                                variant="text-primary"
                                underline
                            >
                                How it works
                            </section-header>
                            <h3 class="text-responsive mb-2 mt-2">
                                Qualify in seconds. Close in minutes.<br>$0 to get.
                            </h3>
                            <h5 class="text-responsive text-responsive fw-light">
                                Just 15 minutes from start to close, all from the comfort of your home.
                            </h5>
                            <a
                                class="d-none d-md-inline-block btn btn-primary"
                                href="#origination-form"
                            > Check Offer </a>
                        </div>
                        <div class="col-md">
                            <ol class="list-circles mt-4">
                                <li>
                                    <h6 class="text-responsive mb-0">
                                        Check Offer
                                    </h6>
                                    <p class="text-responsive">
                                        Answer a few simple questions to quickly confirm your identity and home.
                                    </p>
                                </li>
                                <li>
                                    <h6 class="text-responsive mb-0">
                                        Confirm Income
                                    </h6>
                                    <p class="text-responsive">
                                        A flexible yet simple process to instantly verify your bank account or pay stubs. You pick which one works for you.
                                    </p>
                                </li>
                                <li>
                                    <h6 class="text-responsive mb-0">
                                        Sign Documents
                                    </h6>
                                    <p class="text-responsive">
                                        Simply and easily connect with our notary to sign your documents entirely digitally.
                                    </p>
                                </li>
                            </ol>
                            <div class="d-grid">
                                <a
                                    class="d-block d-md-none btn btn-primary mt-5"
                                    href="#origination-form"
                                > Check Offer </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section-block>

            <divider-line />

            <section-block>
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col col-md-6">
                            <section-header
                                variant="text-primary"
                                underline
                            >
                                Savings
                            </section-header>
                            <h2 class="text-responsive mt-2">
                                Save up to 50% on monthly interest<sup><a href="#footnote_2">2</a></sup>
                            </h2>
                            <h5 class="text-responsive fw-light">
                                Stop paying thousands a year in interest from your high-interest debt, like a credit card or personal loan. You could save 50% on interest alone.
                            </h5>
                        </div>
                        <div class="col col-md-5 offset-md-1 mt-5 mt-md-0">
                            <h5 class="text-responsive">
                                Others <span class="fw-normal">vs.</span> Aven
                            </h5>
                            <p class="text-responsive">
                                <span
                                    class="fw-bold"
                                >Reduce interest by up to 50%<sup><a href="#footnote_2">2</a></sup></span>. See what you could save on a $10,000 balance if you're trying to pay it off over 3 years.
                            </p>
                            <card-comparison-carousel />
                            <p class="small mt-2 text-muted">
                                Estimate assumes no additional transactions are made and the suggested “monthly” payment is paid every month for 3 years. Paying only the min payment of 1% principal &
                                interest increases the “Total” amount paid and will not pay off the balance in 3 years. See
                                <a
                                    href="#"
                                    @click="getDocument(LegalDocumentTypes.earlyHELOCDisclosure)"
                                >Min Payment Example</a> for repayment using min payment.
                            </p>
                        </div>
                    </div>
                </div>
            </section-block>

            <divider-line />

            <limits-section />

            <divider-line />
            <!-- uncomment on 7/26/21 -->
            <!-- <journey-section />-->

            <divider-line />

            <home-equity-section />

            <divider-line />

            <section-block>
                <div class="container">
                    <div class="row align-items-start align-items-md-center">
                        <div class="col-sm">
                            <section-header
                                variant="text-primary"
                                class="text-md-center d-md-flex flex-column align-items-center"
                                underline
                            >
                                Cash Back
                            </section-header>
                            <h2 class="text-responsive mb-2 mb-md-1 mt-2 text-md-center">
                                1.5% unlimited cashback on all purchases <sup><a href="#footnote_5">5</a></sup>
                            </h2>
                            <h5 class="text-responsive fw-light text-md-center mb-0">
                                Earn unlimited 1.5% cashback on every single purchase on your Aven card.
                            </h5>
                        </div>
                    </div>
                </div>
            </section-block>

            <divider-line />

            <fees-section />

            <divider-line />

            <benefits-section />

            <divider-line />

            <home-improvement-section />

            <divider-line />

            <cash-out-section />

            <divider-line />

            <about-us-section />

            <divider-line />

            <reviews-section />

            <divider-line />

            <faq-section>
                <faq
                    :question="$t('components.faq.saveMore.question')"
                    :answer="$t('components.faq.saveMore.answerHtml')"
                />
                <faq
                    :question="$t('components.faq.homeEquityLoan.question')"
                    :answer="$t(`experiments.${heraclesParameter.EXPERIMENT_NAME}.homeEquityLoan.answerHtml`)"
                />
                <faq
                    :question="$t('components.faq.criteria.question')"
                    :answer="$t('components.faq.criteria.answerHtml')"
                />
            </faq-section>

            <divider-line />

            <section-block>
                <div class="container">
                    <div class="row align-items-start">
                        <div class="col-sm">
                            <section-header
                                variant="text-primary"
                                underline
                            >
                                Contact Us
                            </section-header>
                            <h2 class="my-2">
                                Reach out with any questions
                            </h2>
                            <h5
                                class="mb-3 text-responsive fw-light"
                                v-html="$t('pages.marketing.landing.reengage.contactInfoHtml')"
                            />
                            <a
                                :href="smsHref"
                                class="btn btn-secondary d-block d-md-inline-block mb-2"
                            >
                                {{ $t('pages.marketing.landing.reengage.messageUs') }}
                            </a>
                        </div>
                        <div class="d-block d-md-none container my-5">
                            <hr>
                        </div>
                        <div class="col-sm">
                            <section-header
                                variant="text-primary"
                                underline
                            >
                                Join
                            </section-header>
                            <h2 class="my-2">
                                Check offers
                            </h2>
                            <origination-invite-form
                                id-prefix="footer-origination-form"
                                class="mw-invite-form"
                                :code-required="codeRequired"
                                :show-forgot-code-option="showForgotCodeOption"
                                :forgot-code-link="heraclesParameter.JOIN_PATH"
                                ref="originationInviteFormFooter"
                                show-safe-credit-score
                                cta-text="Check your offer"
                            />
                        </div>
                    </div>
                </div>
            </section-block>
        </div>
    </marketing-layout>
</template>

<script>
    import Marketing from '@/layouts/Marketing'
    import { i18n } from '@/utils/i18n'
    import SectionBlock from '@/components/SectionBlock'
    import { contact_info } from '@/utils/contact-info-dictionary'
    import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
    import { Flows } from '@/flow/flowController'
    import SectionHeader from '@/components/SectionHeader'
    import Faq from '@/components/FaqExperimental'
    import OriginationInviteForm from '@/components/OriginationInviteForm'
    import { experimentsMixin } from '@/mixins/experimentsMixin'
    import CardHandSmall from '@/components/landing/CardHandSmall'
    import BenefitsSection from '@/components/landing/BenefitsSection'
    import LimitsSection from '@/components/landing/LimitsSection'
    import HomeEquitySection from '@/components/landing/HomeEquitySection'
    import DividerLine from '@/components/DividerLine'
    import HomeImprovementSection from '@/components/landing/HomeImprovementSection'
    import FaqSection from '@/components/landing/FaqSection'
    import FeesSection from '@/components/landing/FeesSection'
    import CardComparisonCarousel from '@/components/CardComparisonCarousel'
    import CardHandMediumLarge from '@/components/landing/CardHandMediumLarge'
    import CashOutSection from '@/components/landing/CashOutSection'
    import AboutUsSection from '@/components/landing/AboutUsSection'
    import { getDocumentForSessionAndOpen } from '@/utils/document'
    import { logger } from '@/utils/logger'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import WhatIsItSection from '@/components/landing/WhatIsItSection'
    import ReviewsSection from '@/components/landing/ReviewsSection'
    import { LegalDocumentTypes } from '@/services/api'
    import { reloadPageWithCleanState } from '@/utils/routerUtils'
    // import JourneySection from '@/components/landing/JourneySection'

    const SITE_VERSION = '2.5'

    export default {
        name: 'MoapV1NoBtFeeLanding',
        metaInfo: {
            title: 'Aven HELOC Card: A Credit Card backed by Home Equity',
            meta: [
                { name: 'description', content: String(i18n.t('pages.marketing.landing.description')) },
                { vmid: 'robots', name: 'robots', content: 'noindex' },
            ],
        },
        props: {
            codeRequired: {
                type: Boolean,
                required: true,
            },
            showForgotCodeOption: {
                type: Boolean,
                required: true,
            },
        },
        components: {
            'what-is-it-section': WhatIsItSection,
            'about-us-section': AboutUsSection,
            'reviews-section': ReviewsSection,
            'cash-out-section': CashOutSection,
            'card-hand-medium-large': CardHandMediumLarge,
            'card-comparison-carousel': CardComparisonCarousel,
            'fees-section': FeesSection,
            'faq-section': FaqSection,
            'home-improvement-section': HomeImprovementSection,
            'divider-line': DividerLine,
            'home-equity-section': HomeEquitySection,
            'limits-section': LimitsSection,
            // 'journey-section': JourneySection,
            'benefits-section': BenefitsSection,
            'card-hand-small': CardHandSmall,
            'origination-invite-form': OriginationInviteForm,
            'section-header': SectionHeader,
            'section-block': SectionBlock,
            'marketing-layout': Marketing,
            faq: Faq,
        },
        mixins: [experimentsMixin],
        data: function () {
            return {
                LegalDocumentTypes,
            }
        },
        created: function () {
            appSessionStorage.setItem(sessionStorageKey.currentFlow, Flows.origination)
        },
        mounted: function () {
            if (appSessionStorage.getItem(sessionStorageKey.jwtTokens)) {
                logger.info(`User went to landing page with jwt tokens! Clearing their stale state`)
                return reloadPageWithCleanState()
            }

            this.logViewEvent()
        },
        computed: {
            smsHref() {
                return `sms:${contact_info.sms}`
            },
        },
        methods: {
            getDocument: async function (docType) {
                try {
                    this.$logEvent('click_link_get_document', { docType })
                    await getDocumentForSessionAndOpen(docType)
                } catch (e) {
                    logger.error(`failed to open pdfs document`, e)
                    ApiErrorHandler(e)
                }
            },
            logViewEvent: function () {
                this.$logEvent('view_landing_default', { query: window.location.search, version: SITE_VERSION })
            },
        },
    }
</script>

<style lang="scss">
    @import '../../../../styles/pages/marketing/landingV2_5';
</style>
